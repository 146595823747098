import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Container, Row, Col} from "react-bootstrap";
import MailchimpForm from "../Mailchimp";

import logo from "assets/img/logo.svg";
import whiteLogo from "assets/img/white-logo.svg";
import heroEye from "assets/img/hero-eye.svg";
import arrowBlackIcon from "assets/img/arrow-black-icon.svg";
import arrowWhiteIcon from "assets/img/arrow-white-icon.svg";
import cardOneImg from "assets/img/card-1-img.svg";
import cardTwoImg from "assets/img/card-2-img.svg";
import cardThreeImg from "assets/img/card-3-img.svg";
import cardFourImg from "assets/img/card-4-img.svg";
import twitterIcon from "assets/img/twitter-icon.svg";
import telegramIcon from "assets/img/telegram-icon.svg";
import discordIcon from "assets/img/discord-icon.svg";
import githubIcon from "assets/img/github-icon.svg";
import mediumIcon from "assets/img/medium-icon.svg";
import starsBg from "assets/img/stars-bg.svg";
import dotsIcon from "assets/img/3-dots-icon.svg";

const Home = () => {
    const isXsScreen = useMediaQuery({query: '(max-width: 480px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 768px)'});
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isLgScreen = useMediaQuery({query: '(max-width: 1200px)'});

    const screens = {
        isXsScreen,
        isSmScreen,
        isMdScreen,
        isLgScreen
    }

    return (
        <>
            <header>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <img src={logo} alt="Logo"/>
                        </Col>
                    </Row>
                </Container>
            </header>
            <main>
                <section className="hero-section" id="hero-section">
                    <Container>
                        <Row>
                            <div className="eye-wrap">
                                <img src={heroEye} alt="Eye Icon"/>
                            </div>
                            <Col sm={6}>
                                <div className="headline-wrap">
                                    <h1>Your Privacy <span>Matters</span></h1>
                                </div>
                            </Col>
                            <Col sm={6} className="col-link">
                                <div className="link-wrap">
                                    <a href="https://mantanetwork.typeform.com/PrivacySurvey" target="_blank">
                                        <span className="text">Take the<br/> Manta Privacy Survey</span>
                                        <span className="icon"><img src={arrowBlackIcon} alt="Arrow Icon"/></span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="cards-section" id="cards-section">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="card-wrap text-right">
                                    <div className="img-wrap">
                                        <img src={cardOneImg} alt="Card Img"/>
                                    </div>
                                    <div className="text-wrap">
                                        <h2>90%</h2>
                                        <h4>Privacy is Fundamental</h4>
                                        <p>Privacy is normal, however, it is more likely than not, that someone had
                                            looked up your wallet address without your consent.</p>
                                        <br/>
                                        <p>90% of respondents have looked up someone else's wallet address.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="card-wrap text-left">
                                    <div className="text-wrap">
                                        <h2>73%</h2>
                                        <h4>Privacy by Design is Essential</h4>
                                        <p>You shouldn’t feel like you’re being watched...but more likely than not you
                                            are. We need to fix that.</p>
                                        <br/>
                                        <p>73.2% of people have hesitated or completely avoided making a transaction in
                                            because they were worried about the privacy implications of that
                                            transaction.</p>
                                    </div>
                                    <div className="img-wrap">
                                        <img src={cardTwoImg} alt="Card Img"/>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="card-wrap text-right">
                                    <div className="img-wrap">
                                        <img src={cardThreeImg} alt="Card Img"/>
                                    </div>
                                    <div className="text-wrap">
                                        <h2>84%</h2>
                                        <h4>Is it Private Enough?</h4>
                                        <p>The majority of people now think their personal data is less secure now, that
                                            data collection poses more risks than benefits, and believe it is not
                                            possible to go through daily life without being tracked.</p>
                                        <br/>
                                        <p>84% of respondents expressed some level of concern about their wallet address
                                            being linked with their real identity. Wallet addresses occlusion is not
                                            enough.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="card-wrap text-left">
                                    <div className="text-wrap">
                                        <h2>90%</h2>
                                        <h4>Privacy is a Global Problem</h4>
                                        <p>Over 90% of countries in the world participated in building the next
                                            generation of privacy infrastructure with the Trusted Setup. Privacy is a
                                            concern from Liechtenstein to Brunei.</p>
                                    </div>
                                    <div className="img-wrap">
                                        <img src={cardFourImg} alt="Card Img"/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="form-section" id="form-section">
                    <Container>
                        <Row>
                            <div className="stars-wrap">
                                <img src={starsBg} alt="Stars Bg"/>
                            </div>
                            <Col sm={12}>
                                <h2><span>Where do we</span><br/> go from<br/> here?</h2>
                            </Col>
                            <Col sm={12} className="block-with-link-wrap">
                                <div className="dots-wrap">
                                    <img src={dotsIcon} alt="Dots Icon"/>
                                </div>
                                <div className="link-wrap">
                                    <a href="https://mantanetwork.typeform.com/PrivacySurvey" target="_blank">
                                        <span className="text">Take the<br/> Manta Privacy Survey</span>
                                        <span className="icon"><img src={arrowWhiteIcon} alt="Arrow Icon"/></span>
                                    </a>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="form-wrap">
                                    {isMdScreen ? (
                                        <div className="socials-wrap">
                                            <h3>Sign up to receive the report</h3>
                                        </div>
                                    ) : (
                                        <div className="socials-wrap">
                                            <h3>Sign up to receive the report</h3>
                                            <div className="socials-icons-wrap">
                                                <a href="https://twitter.com/mantanetwork" target="_blank">
                                                    <img src={twitterIcon} alt="Twitter Icon"/>
                                                </a>
                                                <a href="https://telegram.com/mantanetworkofficial" target="_blank">
                                                    <img src={telegramIcon} alt="Telegram Icon"/>
                                                </a>
                                                <a href="https://www.discord.gg/mantanetwork" target="_blank">
                                                    <img src={discordIcon} alt="Discord Icon"/>
                                                </a>
                                                <a href="https://github.com/manta-network" target="_blank">
                                                    <img src={githubIcon} alt="GitHub Icon"/>
                                                </a>
                                                <a href="https://mantanetwork.medium.com/" target="_blank">
                                                    <img src={mediumIcon} alt="Medium Icon"/>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    <MailchimpForm />
                                </div>
                                {isMdScreen && (
                                    <div className="socials-wrap">
                                        <div className="socials-icons-wrap">
                                            <a href="https://twitter.com/mantanetwork" target="_blank">
                                                <img src={twitterIcon} alt="Twitter Icon"/>
                                            </a>
                                            <a href="https://telegram.com/mantanetworkofficial" target="_blank">
                                                <img src={telegramIcon} alt="Telegram Icon"/>
                                            </a>
                                            <a href="https://www.discord.gg/mantanetwork" target="_blank">
                                                <img src={discordIcon} alt="Discord Icon"/>
                                            </a>
                                            <a href="https://github.com/manta-network" target="_blank">
                                                <img src={githubIcon} alt="GitHub Icon"/>
                                            </a>
                                            <a href="https://mantanetwork.medium.com/" target="_blank">
                                                <img src={mediumIcon} alt="Medium Icon"/>
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <footer>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="links-wrap">
                                <a href="https://www.manta.network" target="_blank">Manta</a>
                                <a href="https://docs.manta.network" target="_blank">Documentation</a>
                                <a href="https://jobs.lever.co/MantaNetwork" target="_blank">Careers</a>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="copyright-wrap">
                                <div className="img-wrap">
                                    <img src={whiteLogo} alt="Logo"/>
                                </div>
                                <p>Made with <span>💗</span> by p0x labs. All rights reserved.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Home;
